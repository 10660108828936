import React, { useState } from "react";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  const [location, setLocation] = useState({ latitude: null, longitude: null });
  const [responseData, setResponseData] = useState(null);
  const [error, setError] = useState(null);

  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;
          setLocation({ latitude, longitude });
          setError(null);
          fetchAddress(latitude, longitude);
        },
        (err) => {
          setError(err.message);
        }
      );
    } else {
      setError("Geolocation is not supported by this browser.");
    }
  };

  const fetchAddress = async (latitude, longitude) => {
    try {
      const response = await axios.get(
        `http://localhost:8080/api/v1/getAddress`,
        {
          params: {
            latitude,
            longitude,
          },
        }
      );

      setResponseData(response.data);
      console.log("Structured JSON Response:", response.data);
    } catch (error) {
      setError("Error fetching address: " + error.message);
    }
  };

  return (
    <div className="container mt-5">
      <h1 className="text-center mb-4">AWS Geolocation Example</h1>

      <div className="d-flex justify-content-center mb-4">
        <button className="btn btn-primary" onClick={getLocation}>
          Get Current Location
        </button>
      </div>

      {location.latitude && location.longitude ? (
        <div className="card shadow p-4">
          <h2 className="text-center">Location Details</h2>
          <p>
            <strong>Latitude:</strong> {location.latitude}
          </p>
          <p>
            <strong>Longitude:</strong> {location.longitude}
          </p>

          {responseData ? (
            <div className="mt-4">
              <h3 className="text-center">Address Details</h3>
              <table className="table table-striped table-bordered mt-3">
                <thead>
                  <tr>
                    <th>Field</th>
                    <th>Value</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Full Address</td>
                    <td>{responseData.address || "N/A"}</td>
                  </tr>
                  <tr>
                    <td>Street</td>
                    <td>{responseData.street || "N/A"}</td>
                  </tr>
                  <tr>
                    <td>City</td>
                    <td>{responseData.city || "N/A"}</td>
                  </tr>
                  <tr>
                    <td>State</td>
                    <td>{responseData.state || "N/A"}</td>
                  </tr>
                  <tr>
                    <td>Postal Code</td>
                    <td>{responseData.postalCode || "N/A"}</td>
                  </tr>
                  <tr>
                    <td>Country</td>
                    <td>{responseData.country || "N/A"}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          ) : (
            <div className="spinner-border text-primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          )}
        </div>
      ) : error ? (
        <p className="text-danger text-center">{error}</p>
      ) : (
        <p className="text-center">Click the button to get your location.</p>
      )}
    </div>
  );
}

export default App;
